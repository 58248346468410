var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-card-title',[_vm._v("I miei beni")]),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),(_vm.assets.length > 20)?_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.assets,"item-key":"COGNOME","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Stanze per pagina',
          itemsPerPageAllText: 'Tutte le stanze',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Stanze per pagina'"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"item.VALORE_CONVENZIONALE",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.VALORE_CONVENZIONALE.toFixed(2))+" ")]}},{key:"item.Projects",fn:function(ref){
        var item = ref.item;
return _vm._l((item.Projects),function(proj,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s((proj.PER_RIP_ANALITICO * 100).toFixed(2))+"% "+_vm._s(proj.CD_PROGETTO))]),_c('br')]}}],null,true)},[_c('span',[_vm._v(_vm._s(proj.NOME_PROGETTO))])])],1)})}}],null,false,1224478067)}):_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Inventario")]),_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v("Categoria")]),_c('th',{staticClass:"text-left"},[_vm._v("Marca")]),_c('th',{staticClass:"text-left"},[_vm._v("Descrizione")]),_c('th',{staticClass:"text-right"},[_vm._v("Valore")]),_c('th',{staticClass:"text-left"},[_vm._v("Progetto (fondo)")])])]),_c('tbody',_vm._l((_vm.assets),function(item){return _c('tr',{key:item.ID},[_c('td',[_vm._v(_vm._s(item.ID))]),_c('td',[(item.Pictures.length > 0)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-camera")])]}}],null,true)},[_c('v-card',[_c('v-img',{staticClass:"ma-2",attrs:{"height":"300","max-width":"500","contain":"","src":_vm.imageUrl(item.Pictures[0].id)}})],1)],1):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.DS_CATEG_GRUPPO))]),_c('td',[_vm._v(_vm._s(item.MARCA))]),_c('td',[_vm._v(_vm._s(item.DS_BENE))]),_c('td',{staticClass:"text-right"},[_vm._v(" € "+_vm._s(item.VALORE_CONVENZIONALE.toFixed(2))+" ")]),_c('td',_vm._l((item.Projects),function(proj,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s((proj.PER_RIP_ANALITICO * 100).toFixed(2))+"% "+_vm._s(proj.CD_PROGETTO))]),_c('br')]}}],null,true)},[_c('span',[_vm._v(_vm._s(proj.NOME_PROGETTO))])])],1)}),0)])}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }