<template>
  <v-container>
    <v-card elevation="12" min-height="600">
      <v-card-title>I miei beni</v-card-title>
      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-if="assets.length > 20"
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items="assets"
          item-key="COGNOME"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Stanze per pagina',
            itemsPerPageAllText: 'Tutte le stanze',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Stanze per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
          <template v-slot:item.VALORE_CONVENZIONALE="{ item }">
            {{ item.VALORE_CONVENZIONALE.toFixed(2) }}
          </template>
          <template v-slot:item.Projects="{ item }">
            <div v-for="(proj, index) of item.Projects" :key="index">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    >{{ (proj.PER_RIP_ANALITICO * 100).toFixed(2) }}%
                    {{ proj.CD_PROGETTO }}</span
                  >
                  <br />
                </template>
                <span>{{ proj.NOME_PROGETTO }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Inventario</th>
                <th class="text-left"></th>
                <th class="text-left">Categoria</th>
                <th class="text-left">Marca</th>
                <th class="text-left">Descrizione</th>
                <th class="text-right">Valore</th>
                <th class="text-left">Progetto (fondo)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in assets" :key="item.ID">
                <td>{{ item.ID }}</td>
                <td>
                  <v-tooltip v-if="item.Pictures.length > 0" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">mdi-camera</v-icon>
                    </template>
                    <v-card>
                      <v-img
                        class="ma-2"
                        height="300"
                        max-width="500"
                        contain
                        :src="imageUrl(item.Pictures[0].id)"
                      >
                      </v-img>
                    </v-card>
                  </v-tooltip>
                </td>
                <td>{{ item.DS_CATEG_GRUPPO }}</td>
                <td>{{ item.MARCA }}</td>
                <td>{{ item.DS_BENE }}</td>
                <td class="text-right">
                  &euro; {{ item.VALORE_CONVENZIONALE.toFixed(2) }}
                </td>
                <td>
                  <div v-for="(proj, index) of item.Projects" :key="index">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"
                          >{{ (proj.PER_RIP_ANALITICO * 100).toFixed(2) }}%
                          {{ proj.CD_PROGETTO }}</span
                        >
                        <br />
                      </template>
                      <span>{{ proj.NOME_PROGETTO }}</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

export default {
  name: "MyAssets",
  data: () => {
    return {
      originalassets: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    assets() {
      let tmp = [];
      for(let asset of this.originalassets) {
        if(asset.UserAsset.ENDDATE === null) {
          tmp.push(asset)
        }
      }
      return tmp;
    },
    headers() {
      return [
        {
          text: "Inventario",
          align: "start",
          sortable: true,
          value: "ID",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: true,
          value: "DS_CATEG_GRUPPO",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "MARCA",
        },
        {
          text: "Descrizione",
          align: "start",
          sortable: true,
          value: "DS_BENE",
        },
        {
          text: "Valore",
          align: "start",
          sortable: true,
          value: "VALORE_CONVENZIONALE",
        },
        {
          text: "Progetto (fondo)",
          align: "start",
          sortable: true,
          value: "Projects",
        },
      ];
    },
  },

  created: function () {
    this.loading = true;
    this.$http
      .get(
        process.env.VUE_APP_PATRIMONIO_API +
          "/users/" +
          this.$store.state.userMatricola +
          "/assets",
        {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        }
      )
      .then((response) => {
        this.originalassets = response.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    imageUrl(id) {
      return process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + id;
    },

  },
};
</script>